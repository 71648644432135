import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { campaignClient, getSessionClient, setSessionClient } from "../../../api";
import { defaultStorageHelper } from "@spry/campaign-client";
import { b64toBlob } from "../../../helpers/utils";
import axios from "axios";
import Loading from "../../../Components/Loading";

// declare const window: Window &
//   typeof globalThis & {
//     vex: any;
//   };

const FinalImage = () => {
  // disableBrowserHistory()

  const navigate = useNavigate();

  const [participant, setParticipant] = useState<{
    image: string;
    userText: string;
  }>();
  const [submitting, setSubmitting] = useState(true);
  const images = defaultStorageHelper.get("CropImage");

  useEffect(() => {
    const getParticipant = async () => {
      const res = await getSessionClient().call<{
        image: string;
        userText: string;
      }>("getparticipant", {});

      setParticipant(res);
      setSubmitting(false);
    };

    getParticipant();
  }, []);

  if (!images) {
    return <Navigate to="/instructions" replace={true} />;
  }

  const framedImage = JSON.parse(images);

  const getpublichostingurl = async (fileUpload: {
    fileName: string;
    fileType: string;
    sessionKey: string;
  }) => {
    try {
      const res = await campaignClient.call<{ uploadUrl: string; sessionKey: string }>("upload", {
        sessionKey: fileUpload.sessionKey,
        filename: fileUpload.fileName,
        filetype: fileUpload.fileType,
      });
      setSessionClient(campaignClient.recoverSession(res.sessionKey));

      return res.uploadUrl;
    } catch (e) {
      console.log(e);
    }
  };

  async function handleSubmit() {
    setSubmitting(true);
    try {
      const uploadUrl = await getpublichostingurl({
        sessionKey: campaignClient.recoverSession().sessionKey,
        fileName: framedImage.fileName,
        fileType: framedImage.fileType,
      });

      if (!uploadUrl) {
        console.log("not");
        setSubmitting(false);
        return;
      }
      const block = framedImage.final.split(";");
      const contentType = block[0].split(":")[1];
      const realData = block[1].split(",")[1];
      const blob = b64toBlob(realData, contentType);
      axios
        .put(uploadUrl, blob, {
          onUploadProgress: function (progressEvent) {
            // const percentCompleted = Math.round(
            //   (progressEvent.loaded * 100) / progressEvent.total!
            // );
          },
          headers: {
            "Content-Type": framedImage.fileType,
          },
        })
        .then(async (res) => {
          const storage = JSON.parse(images!);
          console.log(storage);

          await campaignClient.call("participant", {
            sessionKey: campaignClient.recoverSession().sessionKey,
            filename: storage.fileName,
            userText: defaultStorageHelper.get("more") || "",
            filetype: storage.fileType,
          });
          defaultStorageHelper.remove("CropImage");
          setSubmitting(false);
          navigate("/thankyou", { replace: true });
        })
        .catch((e) => {
          setSubmitting(false);
          console.log(e);
        });
    } catch (error) {
      setSubmitting(false);
      console.log(error);
    }
  }

  return (
    <section className="section">
      {submitting && <Loading />}
      <div className="shell">
        <div className="section__content">
          <h1>Here is your image?</h1>
          <p>
            If you're ready to have your image shared on the gallery, click "Submit" or download it.
          </p>
        </div>
        {/* /.section__content */}
        <div className="section__container">
          <div className="section__image">
            <img src={framedImage.final} alt="" />
          </div>
          {/* /.section__image */}
        </div>
        {/* /.section__container */}
        <div className="section__form mb-5">
          <div className="form">
            <div className="form__title">
              <h1>{defaultStorageHelper.get("more") || ""}</h1>
            </div>
            {/* /.form__title */}

            {/* /.form__controls */}
            <div className="paddingX">
              <Link to="/addFrame" className=" btn--transparent">
                Back
              </Link>
            </div>
            <div className="paddingX">
              <a
                download={`${framedImage.fileName.split(".")[0]}-${new Date().getTime()}.${
                  framedImage.fileName.split(".")[1]
                }`}
                href={`${framedImage.final}`}
                className=" btn--transparent">
                Download
              </a>
            </div>
            <div className="paddingX">
              <button className="btn width100" onClick={handleSubmit}>
                Submit
              </button>
            </div>
            {/* /.btn */}
          </div>
          {/* /.form */}
        </div>
        {/* /.section__form */}
      </div>
      {/* /.shell */}
    </section>
  );
};

export default FinalImage;
