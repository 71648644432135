import { useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import "./css.css";
import { getSessionClient } from "../../../api";
import Loading from "../../../Components/Loading";
import { getImage } from "../../../helpers/utils";

declare const window: Window &
  typeof globalThis & {
    vex: any;
};

const Gallery = () => {

  const itemsPerPage = 10;

  const [itemOffset, setItemOffset] = useState(0);

  const [gallery, setGallery] = useState<{imageKey:string, userText: string, url:string}[]>([])
  const [currentItems, setCurrentItems] = useState<{imageKey:string, userText: string, url:string}[]>([])

  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    const getGallery = async ()=>{
      const res  = await getSessionClient().call<{gallery: {imageKey:string, userText: string}[]}>('gallery', {})
      const newGallery = []
      for (let i=0; i<res.gallery.length; i++){       
        newGallery.push({
          url: `${getImage(res.gallery[i].imageKey)}`,
          imageKey: res.gallery[i].imageKey,
          userText: res.gallery[i].userText
        })       
      }        
      setGallery(newGallery)
      setCurrentItems(newGallery.slice(itemOffset, itemsPerPage))
      setLoading(false)
    }

    getGallery()

  }, [])

  useMemo(()=>{   
    const tempGallery = gallery.slice(itemOffset, (itemsPerPage + itemOffset))   
    setCurrentItems([...tempGallery])
  }, [itemOffset])
  
  const pageCount = Math.ceil(gallery.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {    
    const newOffset = (event.selected * itemsPerPage) % gallery.length;
    //console.log(`User requested page number ${event.selected + 1}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
  };

  return (
    
    <section className="section">
      {loading && <Loading />}
      <div className="shell">
        <div className="section__content">
          <h1>Gallery</h1>
          <p>
            Check out the great submissions <br />
            we've received so far!            
          </p>
        </div>

        {  currentItems.map((g) => (
            <div className="section__container" key={g.imageKey}>
              <div className="section__image">
                <img src={`${g.url}`} alt="" />
              </div>
              <p className="paddingX">{g.userText}</p>
            </div>
          ))}
        <div className="paginate">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            activeClassName="active"
          />
        </div>
      </div>      
      {/* /.shell */}
    </section>
  );
};

export default Gallery;
