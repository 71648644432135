import { FunctionComponent } from "react";

interface Props {}

const Thankyou: FunctionComponent<Props> | any = () => {
  return (
    <section className="section">
      <div className="shell">
        <div className="section__content">
          <div className="content" style={{ textAlign: "center" }}>
            <img src="assets/images/img-tick.png" alt="tick" className="tickImg" />

            <h1 style={{ marginBottom: "30px" }}>Thanks for participating!</h1>
            <p>Your photo submission will be reviewed and posted on the photo gallery shortly.</p>
            <p>While you wait, check out some of the other submissions!</p>
            <br />
            <br />

            <a href="/gallery" className="btn" style={{ width: "100%" }}>
              View the gallery
            </a>
            <br />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Thankyou;
