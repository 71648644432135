import { SPRYCampaignClient, SessionClient } from "@spry/campaign-client";
import axios from "axios";

const endpoint = process.env.REACT_APP_API_ENDPOINT || "http://localhost:31337/api";
const campaignClient = new SPRYCampaignClient(axios, endpoint);

let sessionClient: SessionClient = campaignClient.recoverSession();
function getSessionClient() {
  return sessionClient;
}
function setSessionClient(_sessionClient: SessionClient) {
  return (sessionClient = _sessionClient);
}

export { endpoint, campaignClient, getSessionClient, setSessionClient };
