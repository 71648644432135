import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { campaignClient, setSessionClient } from "../../../api";
import Loading from "../../../Components/Loading";
import { emailRegex } from "../../../helpers/regexes";

type loginForm = {
  email: string;
};

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<loginForm>();

  const handleLogin = async ({ email }: loginForm) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const { error, message, sessionKey } = await campaignClient.call<{
        error?: string;
        message?: string;
        sessionKey?: string;
      }>("login", { email });
      if (sessionKey) {
        setSessionClient(campaignClient.recoverSession(sessionKey));

        setLoading(false);
        navigate("/instructions");
      }
      if (error) {
        setLoading(false);

        window.vex.dialog.alert({
          unsafeMessage: `${message}`,
          callback: () => {
            navigate("/register");
          },
        });
      }
    } catch (e) {
      setLoading(false);
      window.vex.dialog.alert({
        unsafeMessage: "Error connecting to the server,please try again",
      });
    }
  };
  return (
    <section className="section">
      {loading && <Loading />}

      <div className="shell">
        <div className="section__form">
          <div className="content">
            <h1 style={{ textAlign: "center" }}>Sign in</h1>
            <p style={{ textAlign: "center", fontWeight: "bold" }}>
              Please enter the email you used to sign up.
            </p>
            <div className="formContent">
              <form onSubmit={handleSubmit(handleLogin)} autoComplete={"off"}>
                <div className="form-group">
                  <label>
                    Email <span className="mand">*</span>
                  </label>
                  <input
                    className="form-control email"
                    autoComplete={"falses"}
                    placeholder="Email"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Please enter your email.",
                      },
                      pattern: {
                        value: emailRegex,
                        message: "Please enter a valid email.",
                      },
                    })}
                  />
                  {errors.email && (
                    <p className="error-message">
                      <img
                        src="/images/exclamation-mark.svg"
                        title="Exclamation Mark"
                        alt="Exclamation Mark"
                      />{" "}
                      {errors.email.message}
                    </p>
                  )}
                </div>
                <br />
                <button type="submit" className="btn" style={{ width: "100%" }}>
                  Submit
                </button>
              </form>
              <br />
              <p className="center">
                Don't have an account? <Link to="/register">Sign up</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
