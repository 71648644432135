import React from "react";

const Header: React.FC = () => {
  return (
    <header className="headerCustom">
      <img src="assets/images/logo.svg" alt="Logo" className="Logo" />
    </header>
  );
};

export default Header;
