import { FunctionComponent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as loadImage from "blueimp-load-image";
import "./index.css";
import { defaultStorageHelper } from "@spry/campaign-client";
import { ImageContext } from "../../../helpers/store";
import { campaignClient, getSessionClient } from "../../../api";
import Loading from "../../../Components/Loading";

interface Props {}

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const Instructions: FunctionComponent<Props> | any = () => {

  const navigate = useNavigate();

  const { dispatch } = useContext(ImageContext);

  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    const getParticipant = async ()=>{
      const sessionKey =  getSessionClient().sessionKey
      const res  = await campaignClient.call<{sessionKey:string}>('getparticipant', {sessionKey})
      if (!res.sessionKey){
        navigate("/", {replace: true})
      }
    }

    getParticipant()
  }, [navigate])

  async function handleUpload(data: any) {
   
    if (data.target.files.length === 0) {
      return;
    }
    const type = data.target?.files[0].type;

    if (type === "image/png" || type === "image/jpg" || type === "image/jpeg") {
      setLoading(true)
      defaultStorageHelper.remove("CropImage");
      const imgFile = data.target.files[0];
      // const newUrl = URL.createObjectURL(imgFile);
      // defaultStorageHelper.set("Image", newUrl);
      let oriImage = await loadImage(imgFile, { canvas: true });
      dispatch({ type: "SETUP_IMAGE", payLoad: oriImage.image.toDataURL() });
      defaultStorageHelper.set("CropImage", JSON.stringify({        
        fileName: imgFile.name,
        fileType: imgFile.type,
      }));  
      setLoading(false) 
      navigate("/crop");
    } else {
      setLoading(false) 
      window.vex.dialog.alert({
        unsafeMessage:
          "Wrong format. Please upload a valid .jpg, .jpeg or .png.",
        callback: () => {
          return;
        },
      });
    }
  }
  return (
    <div className="container-fluid">
       {loading && <Loading />}
      <div className="shell">
        <div
          className="col-sm-12 col-md-12"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div className="content" style={{ textAlign: "center" }}>
            <h1 style={{ marginBottom: "30px" }}>How it works</h1>
            <img
              src="assets/images/camera.svg"
              alt="tick"
              className="tickImg"
            />
            <p className="thanks_head">
              Upload a funny photo that captures the adventures of
              family life.
            </p>
            <br />
            <img src="assets/images/crop.svg" alt="tick" className="tickImg" />
            <p className="thanks_head">
              Crop your photo and add a message to the frame.
            </p>
            <br />
            <img src="assets/images/image.svg" alt="tick" className="tickImg" />
            <p className="thanks_head">
              Submit your image to have it shown on the <br />
              Sobeys photo gallery page or download it.
            </p>
            <br />

            <div className="form-upload">
              <div className="form__body">
                <label
                  htmlFor="upload"
                  style={{ width: "100%", marginBottom: "100px" }}
                >
                  <span className="btn" style={{ width: "100%" }}>
                  Take/upload a photo
                  </span>
                </label>

                <div className="form__controls">
                <input
                    type="file"
                    onChange={handleUpload}
                    className="form__field"
                    id="upload"
                    name="upload"
                    accept=".png, .jpg, .jpeg, capture=user"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instructions;
