import React from "react";

const Footer = () => {
  return (
    <div className="footer" style={{ backgroundColor: "#006241" }}>
      <ul className="list-inline footerNav">
        <li>
          <a
            style={{ color: "white" }}
            href="https://www.sobeys.com/en/terms-and-conditions/"
            target="_blank"
            rel="noopener noreferrer">
            Terms of Use
          </a>
        </li>
        <li>
          <a
            style={{ color: "white" }}
            href="https://www.sobeys.com/en/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer">
            Privacy Policy
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
