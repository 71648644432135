import { Route } from "react-router-dom";
import Closed from "../Pages/Closed";
import ComingSoon from "../Pages/ComingSoon";
import Open from "../Pages/Open";
import RedemptionOnly from "../Pages/RedemptionOnly";
import Suspended from "../Pages/Suspended";
import Registration from "../Pages/Open/Registration";
import Thankyou from "../Pages/Open/Thankyou";
import "../i18n";
import Instructions from "../Pages/Open/Instructions";
import Crop from "../Pages/Open/Crop";
import AddFrame from "../Pages/Open/AddFrame";
import FinalImage from "../Pages/Open/FinalImage";
import Gallery from "../Pages/Open/Gallery";
import Login from "../Pages/Open/Login";

export default (
  <>
    <Route path="/" element={<Open />}>
      <Route path="/" element={<Login />} />

      <Route path="/register" element={<Registration />} />
      <Route path="/thankyou" element={<Thankyou />} />
      <Route path="/instructions" element={<Instructions />} />

      <Route path="/crop" element={<Crop />} />
      <Route path="/addFrame" element={<AddFrame />} />
      <Route path="/final" element={<FinalImage />} />
      <Route path="/gallery" element={<Gallery />} />
    </Route>
    <Route path="coming" element={<ComingSoon />} />
    <Route path="closed" element={<Closed />} />
    <Route path="redemption" element={<RedemptionOnly />} />
    <Route path="suspended" element={<Suspended />} />
  </>
);
