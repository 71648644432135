export function b64toBlob(b64Data:string, contentType:string, sliceSize=512) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
  
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
  
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
  
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
  
        var byteArray = new Uint8Array(byteNumbers);
  
        byteArrays.push(byteArray);
    }
  
  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
  }


export const resizeImage = (ratio:any, originCanvas:any) => {
    const tempCanvas:any = document.createElement('canvas');
    const tempctx:any = tempCanvas.getContext('2d');
    // const _temp_ratio = Math.min(ratio.w, ratio.h);
    // tempCanvas.width = Math.ceil(originCanvas.width * _temp_ratio);
    // tempCanvas.height = Math.ceil(originCanvas.height * _temp_ratio);
    tempCanvas.width = Math.ceil(originCanvas.width * ratio.w);
    tempCanvas.height =Math.ceil(originCanvas.height * ratio.h);
    //tempctx.drawImage(originCanvas, 0, 0, FRAME_WIDTH, FRAME_HEIGHT)
    tempctx.drawImage(originCanvas, 0, 0,
        originCanvas.width, originCanvas.height, 0, 0, tempCanvas.width,
        tempCanvas.height);
    return tempCanvas;
};

export const getImage= (imageKey: string) =>{

    return process.env.REACT_APP_ENV === 'local' ? `https://d2hws7aei2ntn4.cloudfront.net/${imageKey}` : `https://${process.env.REACT_APP_PUBLIC_HOSTING_DOMAIN}/${imageKey}`
  

}

