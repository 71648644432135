import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { useRequiredCampaignState } from "../../helpers/campaignStates"

export default function ComingSoon() {
  useRequiredCampaignState("comingsoon")
  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="content textCenter">
              <h1>Site is coming soon!</h1>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
