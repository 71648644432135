import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  canadianPostalCodeRegex,
  emailRegex,
  looseNorthAmericanPhoneRegex,
  stringRegex,
} from "../../../helpers/regexes";
import { useState } from "react";
import { campaignClient, getSessionClient, setSessionClient } from "../../../api";
import Popup from "../../../Components/PopUp/PopIp";
import InputMask from "react-input-mask";
import "./css.css";
import { luhn_validate } from "../../../helpers/lahnAlgorithm";
import Loading from "../../../Components/Loading";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

type RegisterFormData = {
  email: string;
  firstName: string;
  lastName: string;
  postal: string;
  phone: string;
  rules: boolean;
  optin1?: boolean;
  optin2?: boolean;
  optin3?: boolean;
};

export default function Registration() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<RegisterFormData>();

  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  async function handleRegister(data: RegisterFormData) {
    if (submitting) {
      return;
    }

    setSubmitting(true);
    setSubmissionError(false);
    if (data) {
      try {
        const { sessionKey, error, message, code }: any = await getSessionClient()
          .createParticipantBuilder()
          .setEmail(data.email)
          .setFirstName(data.firstName)
          .setLastName(data.lastName)
          .setPostal(data.postal)
          .setPhone(data.phone)
          .setPreferredLanguage("en")
          .addMetadata({
            rules: data.rules,
            optin1: data.optin1,
          })
          .call<{ sessionKey?: string; error?: string; message?: string; code?: string }>(
            "register"
          );

        if (code === "email_resubmitted") {
          window.vex.dialog.alert({
            unsafeMessage: "Sorry, this email has already been registered.",
          }); // already registered
        } else if (code && message) {
          window.vex.dialog.alert({
            unsafeMessage: message,
          });
        } else if (error && message) {
          window.vex.dialog.alert({
            unsafeMessage: message,
          });
        } else if (sessionKey) {
          setSessionClient(campaignClient.recoverSession(sessionKey));
          navigate("/instructions");
        } else {
          setSubmissionError(true);
        }
      } catch {
        setSubmissionError(true);
      }
    }
    setSubmitting(false);
  }

  return (
    <section className="section">
      {submitting && <Loading />}
      <div className="shell">
        <div className="section__form">
          <div className="content" style={{ marginTop: 0 }}>
            <h1 style={{ textAlign: "center" }}>Welcome</h1>
            <p style={{ textAlign: "center", fontWeight: "bold" }}>Please enter your info below.</p>
            <div className="formContent">
              <form onSubmit={handleSubmit(handleRegister)} autoComplete={"off"}>
                <div className="form-group">
                  <label>
                    Email <span className="mand">*</span>
                  </label>
                  <input
                    className="form-control email"
                    autoComplete={"falses"}
                    placeholder="Email"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Please enter your email.",
                      },
                      pattern: {
                        value: emailRegex,
                        message: "Please enter a valid email.",
                      },
                    })}
                  />
                  {errors.email && (
                    <p className="error-message">
                      <img
                        src="/images/exclamation-mark.svg"
                        title="Exclamation Mark"
                        alt="Exclamation Mark"
                      />{" "}
                      {errors.email.message}
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <label>
                    First name <span className="mand">*</span>
                  </label>
                  <input
                    className="form-control name"
                    autoComplete={"falses"}
                    placeholder="First name"
                    {...register("firstName", {
                      required: {
                        value: true,
                        message: "Please enter your first name.",
                      },
                      pattern: {
                        value: stringRegex,
                        message: "Please enter a valid first name.",
                      },
                    })}
                  />
                  {errors.firstName && (
                    <p className="error-message">
                      <img
                        src="/images/exclamation-mark.svg"
                        title="Exclamation Mark"
                        alt="Exclamation Mark"
                      />
                      {errors.firstName.message}
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <label>
                    Last name <span className="mand">*</span>
                  </label>
                  <input
                    className="form-control email"
                    placeholder="Last name"
                    autoComplete={"falses"}
                    {...register("lastName", {
                      required: {
                        value: true,
                        message: "Pease enter your last name.",
                      },
                      pattern: {
                        value: stringRegex,
                        message: "Please enter a valid last name.",
                      },
                    })}
                  />
                  {errors.lastName && (
                    <p className="error-message">
                      <img
                        src="/images/exclamation-mark.svg"
                        title="Exclamation Mark"
                        alt="Exclamation Mark"
                      />{" "}
                      {errors.lastName.message}
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <label>
                    Postal code <span className="mand">*</span>
                  </label>
                  <input
                    className="form-control email"
                    autoComplete={"falses"}
                    placeholder="Postal Code"
                    {...register("postal", {
                      required: {
                        value: true,
                        message: "Please enter your postal code.",
                      },
                      pattern: {
                        value: canadianPostalCodeRegex,
                        message: "Please enter a valid postal code.",
                      },
                    })}
                  />
                  {errors.postal && (
                    <p className="error-message">
                      <img
                        src="/images/exclamation-mark.svg"
                        title="Exclamation Mark"
                        alt="Exclamation Mark"
                      />{" "}
                      {errors.postal.message}
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <label>
                    Phone number <span className="mand">*</span>
                  </label>
                  <input
                    className="form-control email"
                    autoComplete={"falses"}
                    placeholder="Phone Number"
                    {...register("phone", {
                      required: {
                        value: true,
                        message: "Please enter your phone number.",
                      },
                      pattern: {
                        value: looseNorthAmericanPhoneRegex,
                        message: "Please enter a valid phone number.",
                      },
                    })}
                  />
                  {errors.phone && (
                    <p className="error-message">
                      <img
                        src="/images/exclamation-mark.svg"
                        title="Exclamation Mark"
                        alt="Exclamation Mark"
                      />{" "}
                      {errors.phone.message}
                    </p>
                  )}
                </div>

                <small className="lblMand">
                  <span className="mand">*</span> Required field
                </small>

                <div className="checkbox cssBased">
                  <label>
                    <input type="checkbox" className="chkBox" {...register("optin1")} />
                    <span className="cr">
                      <i className="cr-icon fa fa-check" />
                    </span>
                    <p>
                      Yes, I’d like to subscribe to marketing and promotional electronic
                      communications. You can opt out at any time.
                    </p>
                  </label>
                </div>

                <div className="checkbox cssBased">
                  <label>
                    <input
                      type="checkbox"
                      className="chkBox"
                      {...register("rules", {
                        required: {
                          value: true,
                          message:
                            "You must agree to the Official Program Terms in order to register.",
                        },
                      })}
                    />
                    <span className="cr">
                      <i className="cr-icon fa fa-check" />
                    </span>
                    <p>
                      By checking, you agree to Terms of Use and Privacy Policy.
                      <span className="mand">*</span>
                    </p>{" "}
                  </label>
                </div>
                {errors.rules && (
                  <p className="error-message">
                    <img
                      src="/images/exclamation-mark.svg"
                      title="Exclamation Mark"
                      alt="Exclamation Mark"
                    />{" "}
                    {errors.rules.message}
                  </p>
                )}

                <button type="submit" className="btn" style={{ width: "100%" }}>
                  Submit
                </button>
                <br/>
                <p className="center">
                  Already have an account? <Link to="/">Login</Link>
                </p>
                <p className="legal">
                  † By checking this box and clicking submit I agree to receiving electronic
                  messages from Sobeys Capital Incorporated, its affiliates and/or business brands
                  and marketing partners. I understand that I can withdraw consent at any time.
                  Contact us at privacy@sobeys.com or 115 King Street, Stellarton, Nova Scotia, B0K
                  1S0
                </p>

                {submissionError && (
                  <>
                    <br />
                    {submissionError && (
                      <p className="error-message">
                        <i className="fas fa-exclamation-circle" /> Error connecting to the server,
                        please try again
                      </p>
                    )}
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
