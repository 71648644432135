import { createContext, useReducer } from 'react';

export const initialState = {
    image: "",
    crop: "",
    frame: "",
}

export type CropImageContext = {
    imageContext: typeof initialState,
    initial: (img:string)=> void
}
export const ImageContext = createContext<{
    state: typeof initialState;
  dispatch: React.Dispatch<any>
}>({state: initialState, dispatch: () => null});

type Action =
 | { type: 'SETUP_IMAGE', payLoad: string }
 | { type: 'CROP_IMAGE', payLoad: string }
 | { type: 'failure', payLoad: string };



export const cropImageReducer = (state=initialState, action: Action) => {
    switch (action.type) {
        case 'SETUP_IMAGE': 
        return {
            ...state,
            image: action.payLoad
        }
        case 'CROP_IMAGE': 
        return {
            ...state,
            crop: action.payLoad
        }
        default: 
        return state
    
    }
}

export const ImageProvider = ({ children }: {children: React.ReactNode}) => {
    const [state, dispatch] = useReducer(cropImageReducer, initialState);
   
    return (
      <ImageContext.Provider value={{state, dispatch}}>
        {children}
      </ImageContext.Provider>
    );
  };







