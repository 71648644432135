import { useContext, useEffect, useRef, useState } from "react";
import { Navigate,  useNavigate } from "react-router-dom";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { FRAME_HEIGHT, FRAME_WIDTH } from "../AddFrame";
import { ImageContext } from "../../../helpers/store";
import { getSessionClient } from "../../../api";
import Loading from "../../../Components/Loading";

const Crop =  () => {
  // disableBrowserHistory()
  const cropperRef = useRef<ReactCropperElement>(null);

  const { state, dispatch } = useContext(ImageContext);  

  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()

  useEffect(()=>{
    const getParticipant = async ()=>{
      const res  = await getSessionClient().call<{sessionKey:string, status: number}>('getparticipant', {})
      if (!res.sessionKey){
        navigate("/", {replace: true})
      }

      // if (res.status >= 1){
      //   navigate("/thankyou", {replace: true})
      // }

      setLoading(false)
    }

    getParticipant()
  }, [navigate])


  if (!state.image){
    return <Navigate to="/instructions" replace={true} />
  } 

  const handleCropImage = async () => {

    if (typeof cropperRef.current?.cropper !== "undefined") {

      dispatch({ type: "CROP_IMAGE", payLoad: cropperRef.current?.cropper.getCroppedCanvas().toDataURL() });
     

    //   defaultStorageHelper.set("CropImage", JSON.stringify({
    //  ...imageFile,
    //  crop: cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
    // }));

    navigate("/addFrame");  

    }   
     
  }

  return (
     <div className="container-fluid">
       {loading && <Loading />}
      <div className="col-sm-12 col-md-12" style={{ display: "flex", alignItems: "center" }}>
        <div className="content" style={{ textAlign: "center" }}>
          <h1 style={{ marginBottom: "30px" }}>Crop and adjust your photo</h1>
          <p className="thanks_head">
            Tap on the image and adjust the size and position within the frame. Once you like what
            you see, tap "Next". Or take/upload a new pic.
          </p>

          <div className="box__body">
            <div className="photo" style={{ position: "relative", width: "100%",  margin: "5rem auto" }}>             

              { state && <Cropper
                src={state.image}
                style={{
                  height: "100%",
                  width: "100%",
                }}
                initialAspectRatio={FRAME_WIDTH / FRAME_HEIGHT}  
                aspectRatio={FRAME_WIDTH / FRAME_HEIGHT}             
                            guides={false}
                            //minCropBoxHeight={FRAME_INNER_HEIGHT/2}
                            //minCropBoxWidth={1080}
                            ref={cropperRef}
                            background={false}
                            responsive={true}
                            cropBoxResizable={true}
                            checkOrientation={true}
                            viewMode={3}
                            dragMode="move"
                            //autoCropArea={1}
               // crop={onCrop}                 

              />
}
            </div>
          </div>

          <div className="form__actions grid">            
            <div className="grid__col--1of2">
              {" "}
              <a href="/instructions" className="btn--transparent ">
                Retry
              </a>
            </div>

            <div className="grid__col--1of2">
              <button className="btn" onClick={handleCropImage}>
                Next
              </button>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  );
};

export default Crop;
