import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { campaignClient, getSessionClient } from "../../../api";
import { defaultStorageHelper } from "@spry/campaign-client";
import html2canvas from "html2canvas";
import { ImageContext } from "../../../helpers/store";
import Loading from "../../../Components/Loading";
declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const Frames = [
  { title: "messy", path: "/assets/images/frames/messy-small.png" },
  { title: "chaotic", path: "/assets/images/frames/chaotic-small.png" },
  { title: "wild", path: "/assets/images/frames/wild-small.png" },
  { title: "ambitious", path: "/assets/images/frames/ambitious-small.png" },
  { title: "impatient", path: "/assets/images/frames/impatient-small.png" },
  { title: "sleepy", path: "/assets/images/frames/sleepy-small.png" },
  { title: "much energy", path: "/assets/images/frames/muchenergy-small.png" },
  { title: "excited", path: "/assets/images/frames/excited-small.png" },
  // { title: "independent", path: "/assets/images/frames/independent.png" }, //is missing
];

export const FRAME_X_OFFSET = 0;
export const FRAME_Y_OFFSET = 0;
export const FRAME_WIDTH = 1080;
export const FRAME_HEIGHT = 1350;
export const FRAME_INNER_WIDTH = 866;
export const FRAME_INNER_HEIGHT = 1083;

const AddFrame = () => {
  // disableBrowserHistory()
  const imageRef = useRef<any>(null);
  const navigate = useNavigate();

  const { state } = useContext(ImageContext);
  const [submitting, setSubmitting] = useState(true);
  const storageString = defaultStorageHelper.get("CropImage");

  const [userText, setUserText] = useState("");
  const [selectedFrame, setSelectedFrame] = useState(Frames[0].path);

  useEffect(() => {
    const getParticipant = async () => {
      const res = await getSessionClient().call<{ image: string; userText: string }>(
        "getparticipant",
        {}
      );
      setSubmitting(false);
    };

    if (!state.crop || !state.image || !storageString) {
      return navigate("/instructions", { replace: true });
    }

    getParticipant();

    imageRef.current.src = state.crop;
  }, [state]);

  async function saveImage(e: any) {
    e.preventDefault();
    setSubmitting(true);
    const canvas = await html2canvas(window.document.querySelector("#imageFrame"));
    //setFrameImg(canvas.toDataURL())
    try {
      const storage = JSON.parse(storageString!);

      await defaultStorageHelper.set(
        "CropImage",
        JSON.stringify({
          ...storage,
          final: canvas.toDataURL(),
        })
      );
      defaultStorageHelper.set("more", userText);

      setSubmitting(false);
      navigate("/final");
    } catch (error) {
      setSubmitting(false);
      console.log(error);
    }
  }

  return (
    <section className="section">
      {submitting && <Loading />}
      <div className="shell">
        <div className="section__content">
          <h1>Add your message</h1>
          <p>
            Select the text you would like displayed within your photo frame. Then, add a
            description for your selfie. This will appear below your photo in the gallery.
          </p>
        </div>
        {/* /.section__content */}
        <div className="section__container" id="imageFrame">
          <div className="section__frame">
            <img src={selectedFrame} alt="" />
          </div>
          {/* /.section__frame */}
          <div className="section__image">
            <img ref={imageRef} alt="" />
          </div>
          {/* <div className="section__image">
            {frameImg}
          </div> */}
          {/* /.section__image */}
        </div>
        {/* /.section__container */}
        <div className="section__form">
          <div className="form">
            <div className="form__title">
              <h1>Select your frame</h1>
            </div>
            {/* /.form__title */}

            <div className="form__radios">
              {Frames.map(({ path, title }) => (
                <div className="form__radio" key={title}>
                  <div className="radio">
                    <input
                      type="radio"
                      name="frame"
                      id={title}
                      onClick={() => setSelectedFrame(path)}
                      defaultChecked={selectedFrame === path ? true : false}
                    />
                    <label style={{ border: "2px solid #15161c" }} htmlFor={title}>
                      So {title}
                    </label>
                  </div>
                </div>
              ))}
            </div>

            <div className="form__controls">
              <label htmlFor="description">Tell us more about the moment in this photo</label>
              <input
                type="text"
                id="description"
                autoComplete={"falses"}
                onChange={(e) => {
                  e.preventDefault();
                  setUserText(e.target.value);
                }}
                placeholder="Tell as more..."
              />
            </div>
            {/* /.form__controls */}
            <div className="form__actions paddingBottom">
              <Link to="/crop" className=" btn--transparent">
                Back
              </Link>
              {/* /.btn */}
              <a href="#" className="btn text-white" onClick={saveImage}>
                I like it!
              </a>

              {/* /.btn */}
            </div>

            {/* /.form__actions */}
          </div>
          {/* <img src={frameImg} alt="" style={{width: "100%", margin: "0 40px"}} /> */}
          {/* /.form */}
        </div>
        {/* /.section__form */}
      </div>
      {/* /.shell */}
    </section>
  );
};

export default AddFrame;
